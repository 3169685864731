import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import {
	Inject,
	Injectable,
	InjectionToken,
	Optional,
	PLATFORM_ID,
	isDevMode,
	makeStateKey,
	signal,
	StateKey,
	TransferState,
} from '@angular/core';

import { Request } from 'express';
import { readFileSync } from 'fs';
import { CookieService } from 'ngx-cookie-service';
import { join } from 'path';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { THEME_CONSTANTS } from '../1_settings/theme';
import { parseStringToIntOrDefault } from '../helpers/string.helper';
import { ApiService } from './api.service';
import { StatefulService } from './stateful.service';
import { FeatureService } from './feature.service';
import { ContextResponse } from '@woolworthsnz/trader-api';
@Injectable({
	providedIn: 'root',
})
export class AppSettings {
	[x: string]: any;
	apiUrl: string;
	shell: boolean;
	embedCookieName: string;
	chatWidgetUrl: string;
	breakpoints: { [breakpoint: string]: string } = {};
	baseUrl = '/api/v1/';
	baseUrlV2 = '/api/v2/';
	baseUrlWithoutVersion = '/api/v';
	basePageUrl = 'shop';
	features = {};
	constants = THEME_CONSTANTS;
	endpoints: { [endpoint: string]: string } = {};
	currentCampaignId: number;
	buildNumber: string;
	wpayApiUrl: string;
	wpayApiKey: string;
	tokenAuthExclusions: string[] = [];
	accountBaseUrl: string;
	ssoBaseUrl: string;
	iamBaseUrl: string;
	traderCallbackUrl: string;
	mobileWebClientId: string;
	traderWebClientId: string;
	kcIdpHint: string;
	mobileApiUrl: string;
	ssuCaptchaKey: string;
	ssuApiUrl: string;
	pricingApiUrl: string;
	gr4vyId: string;
	wPayEnvironment: 'sandbox' | 'production' | undefined;
	wPayMerchantId: string;
	wPayDeviceFingerprintUrl: string;
	wPayClickToPayId: string;
	wPayClickToPayName: string;
}

export const APP_SETTINGS = new InjectionToken<AppSettings>('AppSettings');

export const API_URL = new InjectionToken('api_url');

export const SSU_COOKIE = 'cw-ssuflow';

export const APP_SETTINGS_PROVIDER = {
	provide: APP_SETTINGS,
	useClass: AppSettings,
};

export const REQUEST = new InjectionToken<Request>('REQUEST');
export const RESPONSE = new InjectionToken<Response>('RESPONSE');

export const REQUEST_PROVIDER = {
	provide: REQUEST,
	useValue: { headers: {}, body: {} } as Request,
};

@Injectable({
	providedIn: 'root',
})
export class AppSettingsService extends StatefulService<AppSettings> {
	isDynamicConentShown = signal(false);
	constructor(
		// eslint-disable-next-line @typescript-eslint/naming-convention
		@Optional() @Inject(APP_SETTINGS) _settings: any,
		@Inject(PLATFORM_ID) private platformId: Object,
		@Optional() @Inject(API_URL) private injectedApiUrl: string,
		private apiService: ApiService,
		private cookieService: CookieService,
		@Optional() private transferState: TransferState = new TransferState()
	) {
		super(_settings);
	}

	get settings(): any {
		return this.state;
	}

	get apiUrl(): string {
		if (isPlatformServer(this.platformId) && !this.settings.apiUrl) {
			return `${this.injectedApiUrl}${this.settings.baseUrl}`;
		}
		return `${this.settings.apiUrl}${this.settings.baseUrl}`;
	}

	getBase(): string {
		if (isPlatformServer(this.platformId) && !this.settings.apiUrl) {
			return this.injectedApiUrl;
		}

		const isMobileProxyRequired = this.getIsEmbeddedFromCookies(this.settings.mobileProxyRequiredCookieName);

		return isMobileProxyRequired && this.settings.isEmbeddedInApp
			? this.settings.mobileApiUrl
			: this.settings.apiUrl;
	}

	initialize(): Observable<any> {
		return this.getSettingsFromInjectedJson();
	}

	isDevMode(): boolean {
		return isDevMode();
	}

	useSSU(): boolean {
		return !this.isDevMode() && isPlatformBrowser(this.platformId);
	}

	handleSSUCookie(): void {
		if (this.useSSU() && !this.cookieService.check(SSU_COOKIE)) {
			this.cookieService.set(SSU_COOKIE, '1', 365, '/');
		}

		if (!this.useSSU() && this.cookieService.check(SSU_COOKIE)) {
			this.cookieService.delete(SSU_COOKIE, '/');
		}
	}

	setSSUCookie(): void {
		if (!this.cookieService.check(SSU_COOKIE)) {
			this.cookieService.set(SSU_COOKIE, '1', 365, '/');
		}
	}

	getIsEmbeddedFromCookies(cookieName: string): boolean {
		return this.cookieService.check(cookieName);
	}

	getAuthRedirectUrl(currentWindowUrl: string): string {
		return `${currentWindowUrl}${this.settings.isEmbeddedInApp ? '?embedWeb=1' : ''}`;
	}

	getSSUClientId(): string {
		return this.settings.isEmbeddedInApp ? this.settings.mobileWebClientId : this.settings.traderWebClientId;
	}

	getSettingsFromInjectedJson(): Observable<AppSettings> {
		let settings: Observable<AppSettings>;
		const settingsPath = 'assets/settings.json';

		const key: StateKey<string> = makeStateKey<string>(settingsPath);

		if (isPlatformServer(this.platformId)) {
			// load settings.json from filesystem
			const distFolder = join(process.cwd(), 'dist/apps/pluto/browser');
			const fullPath = join(distFolder, 'assets/settings.json');
			const settingsJson = JSON.parse(readFileSync(fullPath, 'utf-8'));

			// save a client side copy of the settings.json before modifying for absolute url on server
			this.transferState.set(key, { ...settingsJson });
			settingsJson.apiUrl = this.injectedApiUrl;
			settings = of(settingsJson);
		} else {
			// get settings from transfer state if available
			const storedResponse = this.transferState?.get<any>(key, null);
			if (storedResponse) {
				const response = storedResponse;
				this.transferState.remove(key);
				settings = of(response);
			} else {
				settings = this.apiService.get('/assets/settings.json');
			}
		}

		return settings.pipe(
			tap((r) => {
				const isEmbeddedInApp = this.getIsEmbeddedFromCookies(r.embedCookieName);
				const isMobileProxyRequired = this.getIsEmbeddedFromCookies(r.mobileProxyRequiredCookieName);
				this.setState({
					// new Moible App SSU (Keycloak) solution requires Trader UI calls Trader API via Mobile BFF API reverse proxy (to inject auth token)
					apiUrl: isEmbeddedInApp && isMobileProxyRequired ? r.mobileApiUrl : r.apiUrl,
					shell: r.shell,
					chatWidgetUrl: r.oliveChatWidgetUrl,
					isEmbeddedInApp,
					currentCampaignId: r.currentCampaignId,
					googleApiKey: r.googleApiKey,
					buildNumber: r.buildNumber,
					siteLocationApiUrl: r.siteLocationApiUrl,
					wpayApiUrl: r.wpayApiUrl,
					wpayApiKey: r.wpayApiKey,
					iamBaseUrl: r.iamBaseUrl,
					accountBaseUrl: r.accountBaseUrl,
					ssoBaseUrl: r.ssoBaseUrl,
					traderCallbackUrl: r.traderCallbackUrl,
					kcIdpHint: r.kcIdpHint,
					mobileProxyRequiredCookieName: r.mobileProxyRequiredCookieName,
					mobileApiUrl: r.mobileApiUrl,
					ssuCaptchaKey: r.ssuCaptchaKey,
					ssuApiUrl: r.ssuApiUrl,
					mobileWebClientId: r.mobileWebClientId,
					traderWebClientId: r.traderWebClientId,
					csDeviceFingerprintUrl: r.csDeviceFingerprintUrl,
					orgId: r.orgId,
					merchantId: r.merchantId,
					productAggregatorUrl: r.productAggregatorUrl,
					gr4vyId: r.gr4vyId,
					wPayEnvironment: r.wPayEnvironment,
					wPayMerchantId: r.wPayMerchantId,
					wPayDeviceFingerprintUrl: r.wPayDeviceFingerprintUrl,
					wPayClickToPayId: r.wPayClickToPayId,
					wPayClickToPayName: r.wPayClickToPayName,
				});
			})
		);
	}

	getPageTitle = (item: string, name = ''): string => {
		const title = this.settings?.isEmbeddedInApp ? 'embeddedTitle' : 'title';
		return this.settings?.meta[item][title].replace('{name}', name);
	};

	getMetaTitle = (item: string, name = ''): string => this.settings?.meta[item].title.replace('{name}', name);

	getMetaDescription = (item: string, name = ''): string =>
		this.settings?.meta[item].description.replace('{name}', name);

	getMetaUrl = (item: string): string => this.settings?.meta[item].url;

	getMetaImage = (item: string): string => this.settings?.meta[item].image;

	getBreakpoints = (): { [breakpoint: string]: string } => this.settings?.breakpoints;

	getBreakpointSize = (
		breakpoint: 'mobile' | 'tablet-portrait' | 'tablet' | 'desktop' | 'large' | 'xlarge'
	): number => {
		const breakpointAsString = this.settings?.breakpoints[breakpoint];
		if (!breakpointAsString) {
			return 0;
		}

		const cleanBreakpoint = breakpointAsString.trim().replace('px', '');
		return parseStringToIntOrDefault(cleanBreakpoint, 0);
	};

	getEndpoint = (endpoint: string, apiVersion = 1): string => {
		const qualifiedEndpoint = this.settings.endpoints[endpoint];

		if (!qualifiedEndpoint) {
			throw new Error(`${endpoint} is an invalid endpoint`);
		}

		return `${this.getBase()}${this.settings.baseUrlWithoutVersion || '/api/v'}${apiVersion}/${qualifiedEndpoint}`;
	};

	getTransition(transition: keyof AppSettingsService['settings']['constants']['transitions']): string {
		const qualifiedTransition = this.settings.constants.transitions[transition];

		if (!qualifiedTransition) {
			throw new Error(`${String(transition)} is an invalid transition`);
		}

		return qualifiedTransition;
	}

	getEasing(easing: keyof AppSettingsService['settings']['constants']['easings']): string {
		const qualifiedEasing = this.settings.constants.easings[easing];

		if (!qualifiedEasing) {
			throw new Error(`${String(easing)} is an invalid easing`);
		}

		return qualifiedEasing;
	}

	getMessage = (message: string): string => this.settings?.messages?.[message];

	getSetting = (setting: keyof AppSettings): any => this.settings?.[setting];

	getTimeout = (timeout: string): any => this.settings?.timeouts?.[timeout];

	getSubscriptionMessage = (message: string): any => this.settings?.deliverySubscriptionMessages?.[message];

	getSubscriptionSetting = (setting: string): any => this.settings?.deliverySubscriptionSettings?.[setting];

	getExpressFulfilmentSetting = (setting: string): any => this.settings?.expressFulfilmentSettings?.[setting];

	getExpressFulfilmentMessage = (message: string): any => this.settings?.expressFulfilmentMessages?.[message];

	getScripts = (name: string): any => this.settings?.scriptLocations?.[name];
}
