import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as EDRActions from './everyday-rewards.actions';
import * as EDRSelectors from './everyday-rewards.selectors';

@Injectable({ providedIn: 'root' })
export class EverydayRewardsFacade {
	/** EDR Balance */
	public edrBalance$ = this.store.select(EDRSelectors.selectEdrBalance);
	public fetchingEdrBalance$ = this.store.select(EDRSelectors.selectFetchingEdrBalance);
	public fetchedEdrBalance$ = this.store.select(EDRSelectors.selectFetchedEdrBalance);

	public redemptionPreference$ = this.store.select(EDRSelectors.selectRedemptionPreference);

	/** Boost Offers Count */
	public boostOffersCount$ = this.store.select(EDRSelectors.selectBoostOffersCount);
	public fetchingBoostOffersCount$ = this.store.select(EDRSelectors.selectFetchingBoostOffersCount);
	public fetchedBoostOffersCount$ = this.store.select(EDRSelectors.selectFetchedBoostOffersCount);

	public hasError$ = this.store.select(EDRSelectors.selectHasError);

	constructor(private readonly store: Store) {}

	public fetchEdrBalance(): void {
		this.store.dispatch(EDRActions.fetchEdrBalance());
	}

	public fetchBoostOffersCount(): void {
		this.store.dispatch(EDRActions.fetchBoostOffersCount());
	}
}
