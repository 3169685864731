import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EVERYDAY_REWARDS_FEATURE_KEY, EverydayRewardsState } from './everyday-rewards.reducer';

export const selectEverydayRewardsState = createFeatureSelector<EverydayRewardsState>(EVERYDAY_REWARDS_FEATURE_KEY);

export const selectEdrBalance = createSelector(
	selectEverydayRewardsState,
	(state: EverydayRewardsState) => state.edrBalance
);

export const selectRedemptionPreference = createSelector(selectEdrBalance, (balance) => balance?.redemptionPreference);

export const selectFetchingEdrBalance = createSelector(
	selectEverydayRewardsState,
	(state: EverydayRewardsState) => state.fetchingEdrBalance
);

export const selectFetchedEdrBalance = createSelector(
	selectEverydayRewardsState,
	(state: EverydayRewardsState) => state.fetchedEdrBalance
);

export const selectBoostOffersCount = createSelector(
	selectEverydayRewardsState,
	(state: EverydayRewardsState) => state.boostOffersCount
);

export const selectFetchingBoostOffersCount = createSelector(
	selectEverydayRewardsState,
	(state: EverydayRewardsState) => state.fetchingBoostOffersCount
);

export const selectFetchedBoostOffersCount = createSelector(
	selectEverydayRewardsState,
	(state: EverydayRewardsState) => state.fetchedBoostOffersCount
);

export const selectHasError = createSelector(
	selectEverydayRewardsState,
	(state: EverydayRewardsState) => state.hasError
);
