import { Action, createReducer, on } from '@ngrx/store';
import * as EDRActions from './everyday-rewards.actions';
import { EdrBalanceResponseV2 } from '@woolworthsnz/trader-api';

export const EVERYDAY_REWARDS_FEATURE_KEY = 'everyday_rewards';

export interface EverydayRewardsState {
	edrBalance?: EdrBalanceResponseV2;
	fetchingEdrBalance: boolean;
	fetchedEdrBalance: boolean;
	boostOffersCount?: number;
	fetchingBoostOffersCount: boolean;
	fetchedBoostOffersCount: boolean;
	hasError: boolean;
}

export const everydayRewardsInitialState: EverydayRewardsState = {
	edrBalance: undefined,
	fetchingEdrBalance: false,
	fetchedEdrBalance: false,
	boostOffersCount: undefined,
	fetchingBoostOffersCount: false,
	fetchedBoostOffersCount: false,
	hasError: false,
};

const reducer = createReducer(
	everydayRewardsInitialState,
	on(
		EDRActions.fetchEdrBalance,
		(state): EverydayRewardsState => ({
			...state,
			fetchingEdrBalance: true,
			fetchedEdrBalance: false,
		})
	),
	on(
		EDRActions.fetchEdrBalanceComplete,
		(state, { edrBalanceResponse }): EverydayRewardsState => ({
			...state,
			edrBalance: edrBalanceResponse,
			fetchingEdrBalance: false,
			fetchedEdrBalance: true,
			hasError: false,
		})
	),
	on(
		EDRActions.fetchEdrBalanceFailed,
		(state): EverydayRewardsState => ({
			...state,
			fetchingEdrBalance: false,
			hasError: true,
		})
	),
	on(
		EDRActions.fetchBoostOffersCount,
		(state): EverydayRewardsState => ({
			...state,
			fetchingBoostOffersCount: true,
			fetchedBoostOffersCount: false,
		})
	),
	on(
		EDRActions.fetchBoostOffersCountComplete,
		(state, { boostOffersCount }): EverydayRewardsState => ({
			...state,
			boostOffersCount,
			fetchingBoostOffersCount: false,
			fetchedBoostOffersCount: true,
			hasError: false,
		})
	),
	on(
		EDRActions.fetchBoostOffersCountFailed,
		(state): EverydayRewardsState => ({
			...state,
			fetchingBoostOffersCount: false,
			hasError: true,
		})
	)
);

export function everydayRewardsReducer(state: EverydayRewardsState, action: Action): EverydayRewardsState {
	return reducer(state, action);
}
