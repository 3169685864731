import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, delay, map, mergeMap, switchMap } from 'rxjs/operators';
import { TealiumUtagService } from '@woolworthsnz/analytics';
import { CustomWindow, ShopperService, UserAuthService, WINDOW } from '../../services';
import * as AuthActions from './auth.actions';
import { Action } from '@ngrx/store';
import { of } from 'rxjs';
import { AuthResponse } from '@woolworthsnz/trader-api';

@Injectable()
export class AuthEffects {
	onAuthInit$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthActions.authInitialise),
			map(() => AuthActions.getUserAuthInfo({ sourceAction: AuthActions.authInitialise.type }))
		)
	);

	onAuthRefresh$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthActions.authRefresh),
			map(() => AuthActions.getUserAuthInfo({ sourceAction: AuthActions.authRefresh.type }))
		)
	);

	onGetUserAuthInfo$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthActions.getUserAuthInfo),
			switchMap(({ sourceAction }) =>
				this.userAuthService.fetchUserAuthState().pipe(
					mergeMap((authResponse) => {
						const actions: Action[] = [AuthActions.getUserAuthInfoComplete(authResponse)];

						if (authResponse.isLoggedIn) {
							this.trackUserLoggedIn(authResponse);
							const event = new CustomEvent('sessionInitComplete');
							this.window.document.dispatchEvent(event);
						} else {
							if (this.shopperService.state?.isLoggedIn) {
								actions.push(AuthActions.getUserAuthInfoRetry());
							}
						}

						if (sourceAction === AuthActions.authInitialise.type) {
							actions.push(AuthActions.authInitialiseComplete(authResponse));
						} else {
							actions.push(AuthActions.authRefreshComplete(authResponse));
						}

						return actions;
					}),
					catchError((error) => {
						if (sourceAction === AuthActions.authInitialise.type) {
							return of(AuthActions.authInitialiseFailed({ error }));
						}

						return of(AuthActions.authRefreshFailed({ error }));
					})
				)
			)
		)
	);

	onGetUserAuthInfoRetry$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthActions.getUserAuthInfoRetry),
			delay(1000),
			switchMap(() =>
				this.userAuthService
					.fetchUserAuthState(true)
					.pipe(map((authResponse) => AuthActions.getUserAuthInfoRetryComplete(authResponse)))
			)
		)
	);

	onGetUserAuthInfoRetryComplete$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthActions.getUserAuthInfoRetryComplete),
			map((authResponse) => {
				if (authResponse.isLoggedIn) {
					this.trackUserLoggedIn(authResponse);
				} else {
					if (this.shopperService.state?.isLoggedIn) {
						this.trackUserLoggedOut(authResponse);

						this.shopperService.setState({ isLoggedIn: false });
						return AuthActions.userLoggedOut();
					}
				}

				return AuthActions.authInitialiseComplete(authResponse);
			})
		)
	);

	constructor(
		private actions$: Actions,
		private shopperService: ShopperService,
		private tealiumUtagService: TealiumUtagService,
		private userAuthService: UserAuthService,
		@Inject(WINDOW) private window: CustomWindow,
	) {}

	private trackUserLoggedIn(authResponse: AuthResponse): void {
		this.tealiumUtagService.track('customer_login', {
			...authResponse,
			customer_ols_scvid: authResponse.scvId,
		});
	}

	private trackUserLoggedOut(authResponse: AuthResponse): void {
		this.tealiumUtagService.track('customer_logout', {
			...authResponse,
			customer_logged_in_status: false,
		});
	}
}
