import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as EDRActions from './everyday-rewards.actions';
import { EDRService } from '../services/edr.service';
import { EdrBalanceResponseV2 } from '@woolworthsnz/trader-api';

@Injectable()
export class EverydayRewardsEffects {
	public fetchEdrBalance$ = createEffect(() =>
		this.actions$.pipe(
			ofType(EDRActions.fetchEdrBalance),
			switchMap(() =>
				this.edrService.fetchEdrBalance().pipe(
					map((edrBalanceResponse: EdrBalanceResponseV2) =>
						EDRActions.fetchEdrBalanceComplete({ edrBalanceResponse })
					),
					catchError(() => of(EDRActions.fetchEdrBalanceFailed()))
				)
			)
		)
	);

	public fetchBoostOffersCount$ = createEffect(() =>
		this.actions$.pipe(
			ofType(EDRActions.fetchBoostOffersCount),
			switchMap(() =>
				this.edrService.fetchBoostOffersCount().pipe(
					map((response) =>
						EDRActions.fetchBoostOffersCountComplete({ boostOffersCount: response.totalOffers })
					),
					catchError(() => of(EDRActions.fetchBoostOffersCountFailed()))
				)
			)
		)
	);

	constructor(
		private actions$: Actions,
		private edrService: EDRService
	) {}
}
