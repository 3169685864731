import { Inject, Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router, UrlTree } from '@angular/router';
import { AppSettingsService, CustomWindow, ShopperService, ShopperState, WINDOW } from '@woolworthsnz/styleguide';
import { Observable } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class MandatoryLoginGuard implements CanActivate, CanActivateChild {
	constructor(
		private shopperService: ShopperService,
		private router: Router,
		@Inject(WINDOW) public window: CustomWindow,
		private appSettingsService: AppSettingsService
	) {}
	canActivateChild(): Observable<boolean | UrlTree> {
		return this.canActivate();
	}

	canActivate(): Observable<boolean | UrlTree> {
		return this.shopperService.state$.pipe(
			skipWhile((s) => s.isLoggedIn === undefined),
			map((s: ShopperState) => {
				if (s.isLoggedIn || !this.isMandatoryLoginRequired()) {
					return true;
				}
				const redirectUrl = this.window.location.href;
				const loginUrl = `${this.appSettingsService.getEndpoint(
					'oidcSignInEndpoint'
				)}?redirectUrl=${encodeURIComponent(this.appSettingsService.getAuthRedirectUrl(redirectUrl))}`;
				this.window.location.href = loginUrl;
				return false;
			})
		);
	}

	isMandatoryLoginRequired(): boolean {
		return this.appSettingsService.settings.isEmbeddedInApp;
	}
}
