export const deliveryTimeSlotErrorMessage = {
	delivery_timeslot_expired:
		'The time you had to place your order has passed. \nChoose a time slot to keep shopping.',
} as const;
export const deliveryTimeSlotTitle = {
	delivery_timeslot_expired: 'Time’s up!',
	delivery_timeslot_closed: 'Time slot closed',
	delivery_timeslot_expired_closing_soon: 'Time slot closing soon',
	delivery_timeslot_expired_closing_now: 'Less than 5 minutes left',
} as const;
export const deliveryTimeSlotButton = {
	delivery_timeslot_expired: 'Change time slot',
} as const;
