import { createAction, props } from '@ngrx/store';
import { EdrBalanceResponseV2 } from '@woolworthsnz/trader-api';

const featureGroupName = '[EDR]';

/** Fetch EDR Balance **/
export const fetchEdrBalance = createAction(`${featureGroupName} Fetch EDR Balance`);
export const fetchEdrBalanceFailed = createAction(`${featureGroupName} Fetch EDR Balance Failed`);
export const fetchEdrBalanceComplete = createAction(
	`${featureGroupName} Fetch EDR Balance Complete`,
	props<{
		edrBalanceResponse: EdrBalanceResponseV2;
	}>()
);

/** Fetch Boost Offers Count **/
export const fetchBoostOffersCount = createAction(`${featureGroupName} Fetch Boost Offers Count`);
export const fetchBoostOffersCountFailed = createAction(`${featureGroupName} Fetch Boost Offers Count Failed`);
export const fetchBoostOffersCountComplete = createAction(
	`${featureGroupName} Fetch Boost Offers Count Complete`,
	props<{
		boostOffersCount: number;
	}>()
);
